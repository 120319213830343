// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-confirmation-js": () => import("../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-til-foresatte-js": () => import("../src/pages/info-til-foresatte.js" /* webpackChunkName: "component---src-pages-info-til-foresatte-js" */),
  "component---src-pages-info-til-skoler-js": () => import("../src/pages/info-til-skoler.js" /* webpackChunkName: "component---src-pages-info-til-skoler-js" */),
  "component---src-pages-kjops-vilkaar-js": () => import("../src/pages/kjops-vilkaar.js" /* webpackChunkName: "component---src-pages-kjops-vilkaar-js" */),
  "component---src-pages-namelist-js": () => import("../src/pages/namelist.js" /* webpackChunkName: "component---src-pages-namelist-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-r-js": () => import("../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */)
}

